import React, { useState } from 'react';
import './Login.css';

function Login({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Check for admin credentials
      if (email === 'haluk.yazgi' && password === 'Cygm2025*') {
        const userData = {
          email: 'haluk.yazgi',
          name: 'Haluk Şahin YAZGI',
          token: 'admin-token',
          role: 'admin'
        };
        localStorage.setItem('user', JSON.stringify(userData));
        onLogin(userData);
        return;
      }

      // Check for regular user credentials
      if (email && password) {
        const userData = {
          email,
          name: email.split('@')[0],
          token: 'dummy-token',
          role: 'user'
        };
        localStorage.setItem('user', JSON.stringify(userData));
        onLogin(userData);
      } else {
        setError('Lütfen tüm alanları doldurunuz');
      }
    } catch (err) {
      setError('Geçersiz kullanıcı bilgileri');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h1>MevzuatAI</h1>
        <form onSubmit={handleSubmit}>
          <div className="login-form-group">
            <input
              type="text"
              placeholder="E-posta veya Kullanıcı Adı"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="username"
              required
            />
          </div>
          <div className="login-form-group">
            <input
              type="password"
              placeholder="Şifre"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
              required
            />
          </div>
          {error && <div className="login-error-message">{error}</div>}
          <button type="submit" className="login-button">Giriş Yap</button>
        </form>
      </div>
    </div>
  );
}

export default Login;