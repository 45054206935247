// filepath: /C:/Users/-TARIK-/Desktop/mevzuatai_project/chatbot/src/Chatbot.js
import React, { useState, useEffect, useRef } from "react";
import "./Chatbot.css";
import Login from './Login';

function Chatbot() {
  const [prompt, setPrompt] = useState("");
  const [messages, setMessages] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [activeConversation, setActiveConversation] = useState(null);
  const userMenuRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const activeConversationRef = useRef(activeConversation);
  const [isLoading, setIsLoading] = useState(false);
  const [editingConvId, setEditingConvId] = useState(null);
  const [dropdownConvId, setDropdownConvId] = useState(null);
  const dropdownRef = useRef(null);
  const [loadingConversations, setLoadingConversations] = useState({});
  const [stopGenerating, setStopGenerating] = useState(false);
  const [controller, setController] = useState(null);
  const [isStopping, setIsStopping] = useState(false);
  const sidebarRef = useRef(null);
  const touchStartXRef = useRef(0);
  const touchMoveXRef = useRef(0);

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || null);

  // Add this function to your Chatbot component
  const handleLogin = (userData) => {
    setUser(userData);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('conversations');
    localStorage.removeItem('activeConversation');
    setUser(null);
    setMessages([]);
    setConversations([]);
    setActiveConversation(null);
  };

  useEffect(() => {
    if (user) {
      const storedConversations = localStorage.getItem("conversations");
      if (storedConversations) {
        setConversations(JSON.parse(storedConversations));
      }
  
      const storedActiveConversation = localStorage.getItem("activeConversation");
      if (storedActiveConversation) {
        setActiveConversation(JSON.parse(storedActiveConversation));
      }
    }
  }, [user]);

  useEffect(() => {
    if (activeConversation && messages.length === 0) {
      const storedMessages = localStorage.getItem(`messages_${activeConversation}`);
      if (storedMessages) {
        setMessages(JSON.parse(storedMessages));
      }
    }
  }, [messages.length, activeConversation]);
  useEffect(() => {
    if (activeConversation) {
      const storedMessages = localStorage.getItem(`messages_${activeConversation}`);
      if (storedMessages) {
        setMessages(JSON.parse(storedMessages));
      } else {
        setMessages([]);
      }
    } else {
      setMessages([]);
    }
  }, [activeConversation]);

  

  useEffect(() => {
    // Save messages to local storage whenever it changes
    if (activeConversation) {
      localStorage.setItem(`messages_${activeConversation}`, JSON.stringify(messages));
    }
  }, [messages, activeConversation]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!prompt.trim() || isLoading || isStopping) return; // Add isStopping check

    // Clean up existing controller
    if (controller) {
      try {
        controller.abort();
        setController(null);
      } catch (error) {
        console.error("Error aborting previous controller:", error);
      }
    }

    let sourceConversationId = activeConversation;
    setStopGenerating(false);
    setIsLoading(true);
    
    const newController = new AbortController();
    setController(newController);

    // Create the new user message
    const newMessage = { sender: "user", text: prompt };

    // For new conversations
    if (!sourceConversationId) {
      const newConversationId = Date.now();
      sourceConversationId = newConversationId;

      // First update the messages state directly
      setMessages([newMessage]);

      // Then create the new conversation
      const newConversation = {
        id: newConversationId,
        name: prompt.slice(0, 20) || "New Conversation",
        messages: [newMessage],
        lastUsed: Date.now(),
      };

      setConversations(prev => [...prev, newConversation]);
      setActiveConversation(newConversationId);

      // Save to localStorage immediately
      localStorage.setItem(`messages_${newConversationId}`, JSON.stringify([newMessage]));
    } else {
      // For existing conversations
      const updatedMessages = [...messages, newMessage];
      setMessages(updatedMessages);

      setConversations(prevConversations =>
        prevConversations.map(conv =>
          conv.id === sourceConversationId
            ? {
              ...conv,
              messages: updatedMessages,
              lastUsed: Date.now(),
            }
            : conv
        )
      );

      // Save to localStorage immediately
      localStorage.setItem(`messages_${sourceConversationId}`, JSON.stringify(updatedMessages));
    }

    setLoadingConversations(prev => ({
      ...prev,
      [sourceConversationId]: true,
    }));

    const formattedPrompt = prompt;
    setPrompt("");

    try {
      const lastFiveMessages = messages
        .slice(-6, -1) // Take 5 messages before the new message
        .map(msg => ({
          role: msg.sender === "user" ? "kullanıcı" : "asistant",
          content: msg.text
        }));
      // Add bot message
      const botMessage = { sender: "bot", text: "" };
      setMessages(prev => {
        const updatedMessages = [...prev, botMessage];
        localStorage.setItem(`messages_${sourceConversationId}`, JSON.stringify(updatedMessages));
        return updatedMessages;
      });

      const response = await fetch("https://chatbot-model-g45f.onrender.com/generate", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ prompt: formattedPrompt,
          last_five_messages: lastFiveMessages
         }),
        signal: newController.signal,
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = "";

      while (true) {
        if (stopGenerating) {
          console.log("Generation stopped by user.");
          break;
        }

        const { value, done } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split("\n");

        for (let i = 0; i < lines.length - 1; i++) {
          const line = lines[i];
          if (line.startsWith("data: ")) {
            const content = line.slice(6);
            const formattedContent = content.replace(/\\n/g, "\n");

            setMessages(prev => {
              const lastMessage = prev[prev.length - 1];
              if (lastMessage && lastMessage.sender === "bot") {
                return [
                  ...prev.slice(0, -1),
                  { ...lastMessage, text: lastMessage.text + formattedContent }
                ];
              }
              return [...prev, { sender: "bot", text: formattedContent }];
            });
          }
        }
        buffer = lines[lines.length - 1];
      }

      // Handle any remaining buffer content
      if (buffer.startsWith("data: ")) {
        const content = buffer.slice(6);
        const formattedContent = content.replace(/\\n/g, "\n");

        setMessages(prev => {
          const lastMessage = prev[prev.length - 1];
          if (lastMessage && lastMessage.sender === "bot") {
            return [
              ...prev.slice(0, -1),
              { ...lastMessage, text: lastMessage.text + formattedContent }
            ];
          }
          return [...prev, { sender: "bot", text: formattedContent }];
        });
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted by user');
      } else {
        console.error("Error:", error);
        alert("Error: Unable to connect to the server.");
      }
    } finally {
      setIsLoading(false);
      setLoadingConversations(prev => ({
        ...prev,
        [sourceConversationId || "new"]: false,
      }));
    }
  };
  const handleStopGenerating = async () => {
    if (controller) {
      try {
        setIsStopping(true); // Set stopping state
        controller.abort();
        
        // Immediately update states
        setStopGenerating(true);
        setIsLoading(false);
        setController(null);
        setLoadingConversations(prev => ({
          ...prev,
          [activeConversation || "new"]: false,
        }));
  
        // Add delay before allowing new messages
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // Reset states
        setStopGenerating(false);
        setIsStopping(false);
        
      } catch (error) {
        console.error("Error stopping generation:", error);
        setIsStopping(false); // Make sure to reset on error
      }
    }
  };

  const startNewChat = () => {
    handleStopGenerating(); // Stop any ongoing generation
    setActiveConversation(null);
    activeConversationRef.current = null;
    setMessages([]);
    setPrompt("");
  };

  const selectConversation = (id) => {
    handleStopGenerating(); // Stop any ongoing generation
    const currentConv = conversations.find(
      (conv) => conv.id === activeConversation
    );
    if (
      currentConv &&
      currentConv.messages.length === 0 &&
      conversations.length > 1 &&
      currentConv.lastUsed !== null
    ) {
      setConversations(
        conversations.filter((conv) => conv.id !== activeConversation)
      );
    }

    setActiveConversation(id);
    const conversation = conversations.find((conv) => conv.id === id);
    setMessages(conversation.messages);
  };
  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
    document.documentElement.classList.toggle("dark-theme");
  };

  const handleRename = (convId, newName) => {
    setConversations((prevConvs) =>
      prevConvs.map((conv) =>
        conv.id === convId ? { ...conv, name: newName } : conv
      )
    );
    setEditingConvId(null);
  };

  const handleDelete = (convId) => {
    setConversations((prevConvs) =>
      prevConvs.filter((conv) => conv.id !== convId)
    );
    if (activeConversation === convId) {
      setActiveConversation(null);
      setMessages([]);
    }
    setDropdownConvId(null);
  };

  const handleReaction = (messageIndex, type) => {
    setMessages(
      messages.map((msg, index) => {
        if (index === messageIndex) {
          if (type === "like") {
            return {
              ...msg,
              liked: !msg.liked,
              disliked: false,
            };
          } else {
            return {
              ...msg,
              disliked: !msg.disliked,
              liked: false,
            };
          }
        }
        return msg;
      })
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setUserMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    const textarea = document.querySelector(".chat-input textarea");
    if (!prompt && textarea) {
      textarea.style.height = "40px";
    }
  }, [prompt]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownConvId(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const formatMessage = (text) => {
    const parts = [];
    const splitText = text.split('\n');
  
    const formatInline = (text) => {
      const inlineParts = [];
      const boldSegments = text.split(/(\*\*.*?\*\*)/g);
      boldSegments.forEach((segment, i) => {
        if (segment.startsWith('**') && segment.endsWith('**')) {
          inlineParts.push(<strong key={i}>{segment.slice(2, -2)}</strong>);
        } else {
          inlineParts.push(<span key={i}>{segment}</span>);
        }
      });
      return inlineParts;
    };
  
    splitText.forEach((paragraph, index) => {
      if (paragraph.startsWith('### ')) {
        const content = paragraph.substring(4);
        parts.push(<h3 key={index}>{formatInline(content)}</h3>);
      } else if (paragraph.startsWith('## ')) {
        const content = paragraph.substring(3);
        parts.push(<h2 key={index}>{formatInline(content)}</h2>);
      } else if (paragraph.startsWith('# ')) {
        const content = paragraph.substring(2);
        parts.push(<h1 key={index}>{formatInline(content)}</h1>);
      } else if (paragraph.startsWith('* ')) {
        const listContent = paragraph.substring(2);
        parts.push(
          <li key={index} style={{ marginLeft: '20px' }}>
            {formatInline(listContent)}
          </li>
        );
      } else {
        parts.push(
          <p key={index} className="message-paragraph">
            {formatInline(paragraph)}
          </p>
        );
      }
    });
  
    return parts;
  };
  useEffect(() => {
    const sidebar = sidebarRef.current;
    
    const handleTouchStart = (e) => {
      touchStartXRef.current = e.touches[0].clientX;
    };
  
    const handleTouchMove = (e) => {
      touchMoveXRef.current = e.touches[0].clientX;
      const deltaX = touchStartXRef.current - touchMoveXRef.current;
      
      // If dragging left and sidebar is open
      if (deltaX > 50 && sidebarOpen) {
        setSidebarOpen(false);
      }
    };
  
    if (sidebar) {
      sidebar.addEventListener('touchstart', handleTouchStart);
      sidebar.addEventListener('touchmove', handleTouchMove);
  
      return () => {
        sidebar.removeEventListener('touchstart', handleTouchStart);
        sidebar.removeEventListener('touchmove', handleTouchMove);
      };
    }
  }, [sidebarOpen]);
  

  return (
    <>
      {!user ? (
        <Login onLogin={handleLogin} />
      ) : (    
      <div className="app-container">
        <div className={`top-bar ${sidebarOpen ? "expanded" : "collapsed"}`}>
          <button
            className="toggle-sidebar"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            title={sidebarOpen ? "" : ""}
          >
            <span className="icon">
              <img
                src="/images/sidebar.png"
                alt="Toggle Sidebar"
                style={{
                  width: "20px",
                  height: "20px",
                  filter: isDarkTheme ? "invert(1)" : "none",
                }}
              />
            </span>
          </button>

          <button className="new-chat" onClick={startNewChat} title="">
            <span className="icon">
              <img
                src="/images/newchat.png"
                alt="New Chat"
                style={{
                  width: "20px",
                  height: "20px",
                  filter: isDarkTheme ? "invert(1)" : "none",
                }}
              />
            </span>
          </button>
        </div>

        <div 
          ref={sidebarRef}
          className={`sidebar ${sidebarOpen ? "" : "hidden"}`}>
          <div className="sidebar-header"></div>
          <div className="conversation-list">
            {conversations
              .filter(
                (conv) => conv.messages.length > 0 || conv.id === activeConversation
              )
              .sort((a, b) => {
                if (a.lastUsed === null) return 1;
                if (b.lastUsed === null) return -1;
                return b.lastUsed - a.lastUsed;
              })
              .map((conv) => (
                <div
                  key={conv.id}
                  className={`conversation-item ${
                    conv.id === activeConversation ? "active" : ""
                  }`}
                >
                  <div
                    className="conversation-name"
                    onClick={() => selectConversation(conv.id)}
                  >
                    {editingConvId === conv.id ? (
                      <input
                        type="text"
                        defaultValue={conv.name}
                        autoFocus
                        onBlur={(e) => handleRename(conv.id, e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleRename(conv.id, e.target.value);
                          }
                        }}
                        onClick={(e) => e.stopPropagation()}
                      />
                    ) : (
                      conv.name
                    )}
                  </div>
                  <button
                    className="conv-options-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDropdownConvId(dropdownConvId === conv.id ? null : conv.id);
                    }}
                  >
                    <img
                      src="/images/more_horiz.png"
                      alt="More options"
                      style={{ filter: isDarkTheme ? "invert(1)" : "none" }}
                    />
                  </button>
                  {dropdownConvId === conv.id && (
                    <div className="conv-dropdown" ref={dropdownRef}>
                      <button
                        onClick={() => {
                          setEditingConvId(conv.id);
                          setDropdownConvId(null);
                        }}
                      >
                        <img
                          src="/images/rename.png"
                          alt="Rename"
                          style={{ filter: isDarkTheme ? "invert(1)" : "none" }}
                        />
                        Yeniden Adlandır
                      </button>
                      <button onClick={() => handleDelete(conv.id)}>
                        <img
                          src="/images/trash.png"
                          alt="Delete"
                          style={{
                            filter:
                              "invert(37%) sepia(85%) saturate(2590%) hue-rotate(338deg) brightness(100%) contrast(111%)",
                          }}
                        />
                        <span style={{ color: "#ff4444" }}>Sil</span>
                      </button>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>

        <div className={`chat-container ${isDarkTheme ? "dark-theme" : ""}`}>
          <div className="chat-header">
            <span className="app-title">MevzuatAI</span>

            <div className="header-controls">
              <button
                className="theme-toggle"
                onClick={toggleTheme}
                title={isDarkTheme ? "" : ""}
              >
                <img
                  src={
                    isDarkTheme
                      ? "/images/lighttheme.png"
                      : "/images/darktheme.png"
                  }
                  alt="Theme Icon"
                  style={{
                    width: "30px",
                    height: "30px",
                    filter: isDarkTheme ? "invert(1)" : "none",
                  }}
                />
              </button>

              <div
                className="user-menu"
                ref={userMenuRef}
                onClick={() => setUserMenuOpen(!userMenuOpen)}
              >
                <img
                  src="/images/user.png"
                  alt="User Icon"
                  className="user-icon"
                  style={{
                    filter: isDarkTheme ? "invert(1)" : "none",
                  }}
                />
                <div className={`dropdown-menu ${userMenuOpen ? "open" : ""}`}>
                  {user && (
                    <>
                      <div className="dropdown-item">
                        
                        {user.name}
                      </div>
                      <div className="dropdown-item">
                        
                        Ayarlar
                      </div>
                      <div className="dropdown-item" onClick={handleLogout}>
                        
                        Çıkış Yap
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="chat-messages-and-footer">
            <div className="content-area">
              <div className="chat-messages">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`message-container ${
                    message.sender === "bot" ? "bot-message" : "user-message"
                  }`}
                >
                  {message.sender === "bot" && (
                    <div className="message-left">
                      <img
                        src="/images/logo.png"
                        alt="Bot Logo"
                        className="bot-logo"
                      />
                    </div>
                  )}
                  <div className="message-right">
                    <div className="message-content">
                      {message.sender === "bot" ? (
                        formatMessage(message.text)
                      ) : (
                        <p className="message-paragraph">{message.text}</p>
                      )}
                    </div>
                    {message.sender === "bot" && (
                      <div className="reaction-buttons">
                        <button
                          className={`reaction-btn ${message.liked ? "liked" : ""}`}
                          onClick={() => handleReaction(index, "like")}
                        >
                          <img
                            src={message.liked ? "/images/likeafter.png" : "/images/like.png"}
                            alt="Like"
                          />
                        </button>
                        <button
                          className={`reaction-btn ${message.disliked ? "disliked" : ""}`}
                          onClick={() => handleReaction(index, "dislike")}
                        >
                          <img
                            src={message.disliked ? "/images/dislikeafter.png" : "/images/dislike.png"}
                            alt="Dislike"
                          />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
                <div ref={messagesEndRef} />
              </div>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (prompt.trim() && !isLoading) {
                    handleSubmit(e);
                  }
                }}
                className="chat-input"
              >
                <textarea
                  placeholder="Mesajınızı yazın..."
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" &&
                      !e.shiftKey &&
                      prompt.trim() &&
                      !isLoading
                    ) {
                      e.preventDefault();
                      handleSubmit(e);
                    }
                  }}
                  onInput={(e) => {
                    e.target.style.height = "40px";
                    if (e.target.value) {
                      const newHeight = Math.max(
                        40,
                        Math.min(e.target.scrollHeight, 120)
                      );
                      e.target.style.height = `${newHeight}px`;
                    }
                  }}
                />
                {isLoading ? (
                  <button
                    type="button"
                    onClick={handleStopGenerating}
                    className="stop-button"
                    disabled={isStopping} // Disable during stopping
                  >
                    <i className="fas fa-stop" style={{ fontSize: '16px' }}></i>
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={
                      !prompt.trim() ||
                      loadingConversations[activeConversation || "new"] ||
                      isStopping // Add isStopping check
                    }
                    className={`${
                      prompt.trim() &&
                      !loadingConversations[activeConversation || "new"] &&
                      !isStopping // Add isStopping check
                        ? "active"
                        : "disabled"
                    } ${
                      loadingConversations[activeConversation || "new"]
                        ? "loading"
                        : ""
                    }`}
                  >
                    <i className="fas fa-arrow-right"></i>
                  </button>
                )}
              </form>
            </div>
            <div className="chat-footer"></div>
          </div>
        </div>
      </div>
      )}
    </>
  );
}

export default Chatbot;